import React from 'react';

const MenuModal = ({
  onClose, children, size = 'normal', title,
}) => (
  <div
    role={onClose ? 'button' : undefined}
    onClick={onClose}
    onKeyDown={onClose}
    tabIndex={-1}
    className={(
      `overlap-menu-modal ${size}-menu-modal fixed top-0 right-0 left-0 bottom-0 flex-1 w-full flex flex-col items-center bg-gray-500/75`
    )}
    style={{
      zIndex: 1001,
    }}
  >
    <div
      role='button'
      onClick={(e) => e.stopPropagation()}
      onKeyDown={(e) => e.stopPropagation()}
      tabIndex={-1}
      className='rounded bg-white text-text-120 shadow-modal flex flex-col items-stretch'
    >
      {!!title && <div className='p-5 pb-0 text-lg font-semibold text-center'>{title}</div>}
      <div className='p-5 flex-1 flex flex-col justify-start items-stretch' style={{ overflowY: 'auto' }}>
        {children}
      </div>
    </div>
  </div>
);

export default MenuModal;
