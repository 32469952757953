import axios from 'axios';
import { Buffer } from 'buffer';
import { makeRequest } from '../utils';
import client from './client';

const PROFILE_PATH = '/api/v2/Profile';

export const login = ({ loginId, pinNumber }) => {
  const raw = `${loginId}:${pinNumber}`;
  const encoded = Buffer.from(raw).toString('base64');
  const authorization = `Basic ${encoded}`;

  return makeRequest(axios.post('/api/v2/Auth', {}, { headers: { authorization } }));
};

export const getProfileSummary = () => makeRequest(client.get(`${PROFILE_PATH}/summary`));

export const getProfileDetails = () => makeRequest(client.get(PROFILE_PATH));

export const updateProfileDetails = (details) => makeRequest(client.put(PROFILE_PATH, details));

export const changePassword = (data) => makeRequest(client.post(`${PROFILE_PATH}/ResetPassword`, data));

export const updateV2DetailsFromV1 = (data) => makeRequest(client.patch(`${PROFILE_PATH}/V2Specific`, data));
export const getV2SpecificDetailsForV1 = () => makeRequest(client.get(`${PROFILE_PATH}/V2Specific`));
