// v2 features
export const MOVE_DISPATCHING_V2 = 'move-dispatching-v2';
export const MOVE_SETTLEMENTS_V2 = 'move-settlements-v2';
export const MOVE_MAINTENANCE_REPORTS_V2 = 'move-maintenance-reports-v2';
export const MOVE_REPAIR_ORDERS_V2 = 'move-repair-orders-v2';
export const MOVE_PROFILE_V2 = 'move-profile-v2';
export const MOVE_FUEL_RATES_V2 = 'move-fuel-rates-v2';
export const MOVE_DRIVER_COMPLIANCE = 'move-driver-compliance-v2';
export const MOVE_DRIVER_COMPLIANCE_OCR_UPLOADS = 'move-compliance-ocr-uploads';

// Whether messaging announcements are enabled
export const MESSAGING_ANNOUNCEMENTS = 'messaging-announcements';

// Dispatching
export const MOVE_GET_DIRECTIONS = 'move-get-directions';

// Repair Orders v2
// Enable chassis validation
export const MOVE_REPAIR_ORDERS_V2_CHASSIS_VALIDATION = 'move-repair-orders-v2-chassis-validation';

// Use V2 orders for pro number dropdown
export const MOVE_REPAIR_ORDERS_V2_ORDERS = 'move-repair-orders-v2-orders';

// Common
export const MOVE_CHASSIS_VALIDATION = 'move_chassis_validation';
