import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { setPollIntervals } from 'src/redux/reducers/common';
import { getAppSettings } from 'src/fetch/app';
import isNaN from 'lodash/isNaN';

const QUERY_KEY = 'pollIntervals';
// till now only supports poll intervals, yet to support other settings
const useAppSettings = () => {
  const dispatchAction = useDispatch();

  const pollIntervals = useSelector(({ common: { pollIntervals } }) => pollIntervals);

  useQuery([QUERY_KEY], getAppSettings, {
    refetchInterval: pollIntervals.MoveSettingsPollInterval,
    refetchOnMount: false,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: false,
    onSuccess: (pollIntervals) => {
      const pollIntervalObject = pollIntervals.reduce((acc, { name, value }) => {
        //  try to convert to number
        value = +value;
        if (isNaN(value)) {
          return acc;
        }

        acc[name] = value * 1000;
        return acc;
      }, {});

      dispatchAction(setPollIntervals(pollIntervalObject));
    },
  });

  return useMemo(() => ({ pollIntervals }), [pollIntervals]);
};

export default useAppSettings;
