import qs from 'qs';
import isNil from 'lodash/isNil';

export const getQueryParams = (params, config) => {
  if (isNil(params) || Object.keys(params)?.length === 0) {
    return '';
  }
  const stringified = qs.stringify(params, config);
  return stringified.length > 0 ? `?${stringified}` : '';
};

export const makeRequest = (request) => request
  .then(({ data }) => data)
  .catch((error) => {
    console.log(error);
    throw error;
  });
