import {
  ARRIVED_STATUS, COMPLETE_STATUS, IN_TRANSIT_STATUS, NOT_ACCEPTED_STATUS, NOT_STARTED_STATUS,
} from '../consts';

const groupDispatchesByStatus = (dispatches = []) => {
  // Sort dispatches
  const sorted = [...dispatches]
    // Sort by proNumber to keep the same order
    .sort((a, b) => {
      if (a.proNumber < b.proNumber) {
        return -1;
      }
      return a.proNumber > b.proNumber ? 1 : 0;
    })
    // Sort by priority and first leg number
    .sort((a, b) => {
      if (a.proNumber === b.proNumber) {
        // Dispatch's legNumber = dirst leg's legNumber
        return a.legNumber - b.legNumber;
      }
      if (a.priority === 0) {
        return 1;
      }
      return a.priority < b.priority ? -1 : 0;
    });

  // Group by status
  const grouped = sorted.reduce((acc, dispatch) => {
    const { status } = dispatch;

    switch (status) {
    // Available
    case NOT_ACCEPTED_STATUS:
    case NOT_STARTED_STATUS:
      return { ...acc, available: [...acc.available, dispatch] };
    // In Progress
    case ARRIVED_STATUS:
    case IN_TRANSIT_STATUS:
      return { ...acc, inProgress: [...acc.inProgress, dispatch] };
    case COMPLETE_STATUS:
      return { ...acc, completed: [...acc.completed, dispatch] };
    default:
      return acc;
    }
  }, { available: [], inProgress: [], completed: [] });

  return grouped;
};

export default groupDispatchesByStatus;
