export const ARRIVED_STATUS = 'ARRIVED';
export const CANCELLED_STATUS = 'CANCELLED';
export const COMPLETE_STATUS = 'COMPLETE';
export const IN_TRANSIT_STATUS = 'IN TRANSIT';
export const NOT_ACCEPTED_STATUS = 'NOT ACCEPTED';
export const NOT_STARTED_STATUS = 'NOT STARTED';
export const REJECTED_STATUS = 'REJECTED';

// Signature options
export const PHOTO_SIGN = 0;
export const SIGNATURE_SIGN = 1;
export const NO_SIGN = 2;
