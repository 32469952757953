/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  modal: null,
  props: {},
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    showModal: (state, { payload }) => ({ ...state, ...payload }),
    hideModal: () => initialState,
  },
});

export const { showModal, hideModal } = modalSlice.actions;
export default modalSlice.reducer;
