import React, { forwardRef } from 'react';
import useWindowSize from 'shared/../hooks/useWindowSize';
import SmallLoader from '../indicators/SmallLoader';
import cn from 'classnames';

const Button = forwardRef(({
  id,
  color = 'primary', // primary, secondary, tertiary, red, success,
  mode = 'fill', // fill, flat, link
  fluid = false,
  size = 'normal', // normal, small
  onClick,
  disabled,
  type = 'button',
  children,
  title,
  className,
  icon,
  isLoading,
  style = {},
}, ref) => {
  const { windowWidth } = useWindowSize();
  const isLarge = windowWidth >= 720;

  const buttonClassName = cn(
    'transition p-0 m-0 flex rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 border font-semibold',
    {
      'border-action-tertiary-120 bg-action-tertiary-100 text-gray-700 hover:bg-action-tertiary-120': mode === 'fill' && !disabled && color === 'tertiary',
      'border-primary-100 bg-white text-gray-700 hover:bg-gray-50': mode === 'fill' && !disabled && color === 'secondary',
      'border-red-600 bg-red-600 text-white hover:bg-red-700': mode === 'fill' && !disabled && color === 'red',
      'bg-success-shade-400 border-success-shade-400 hover:bg-success-shade-500': mode === 'fill' && !disabled && color === 'success',
      'border-primary-100 bg-primary-100 text-white hover:bg-primary-120 ': mode === 'fill' && !disabled && color === 'primary',
      'border-action-secondary-20 bg-action-secondary-20 text-text-100': mode === 'fill' && disabled,
      'border-transparent bg-transparent': mode === 'flat',
      'text-action-tertiary-100 hover:text-action-tertiary-120': mode === 'flat' && color === 'tertiary',
      'text-primary-100 hover:text-primary-120': mode === 'flat' && color === 'primary',
      'border-transparent bg-transparent text-action-tertiary-100 hover:text-action-tertiary-120': mode === 'flat',
      'border-transparent bg-transparent text-primary-100 hover:text-primary-120': mode === 'link',
      'px-4 py-2': mode !== 'link' && (title || children) && size !== 'small',
      'text-sm px-2 py-1': size === 'small',
      [className]: className,
    },
  );

  const titleClassName = cn('flex-1 text-center font-semibold', {
    'font-medium': size === 'small',
  });

  return (
    <button
      id={id}
      ref={ref}
      // eslint-disable-next-line react/button-has-type
      type={type}
      onClick={onClick}
      disabled={disabled}
      className={buttonClassName}
      style={{
        minWidth: mode === 'link' || size === 'small' || fluid || (!title && !children) ? '0px' : '155px',
        // eslint-disable-next-line no-nested-ternary
        width: fluid ? (isLarge ? '250px' : '100%') : undefined,
        height: 'fit-content',
        borderWidth: disabled || color === 'secondary' || mode !== 'fill' ? '1px' : '3px',
        ...style,
      }}
    >
      <div className='flex-1 flex gap-1 items-center m-0 p-0'>
        {children}
        {isLoading && <SmallLoader />}
        {icon && !isLoading && <div>{icon}</div>}
        {title && (
          <div
            className={titleClassName}
            style={{
              textDecoration: mode === 'link' ? 'underline' : 'none',
              textUnderlineOffset: '2px',
            }}
          >
            {title}
          </div>
        )}
      </div>
    </button>
  );
});

export default Button;
