import uniq from 'lodash/uniq';

// Build dispatches from all dispatching information
const buildDispatches = ({ dispatches: legs = [], locations = [], requirements = [] }) => {
  // Array of resulting dispatches
  const dispatches = [];

  // Take all the Pro numbers
  const uniqProNumbers = uniq(legs.map(({ proNumber }) => proNumber));

  // Go through the pros to build dispatches
  uniqProNumbers.forEach((proNumber) => {
    const proLegs = legs
      .filter((l) => l.proNumber === proNumber)
      .sort((a, b) => a.legNumber - b.legNumber);

    const legsIds = proLegs.map(({ uuid }) => uuid);
    const proLocations = locations.filter(({ dispatchId }) => legsIds.includes(dispatchId));

    // Pro dispatches as grouped legs by leg bumbers subsequence and drop legs
    const grouped = [];

    // Accumulated legs to build a dispatch
    let acc = [];

    // Go through the legs to build dispatches
    proLegs.forEach((leg, ind) => {
      // Filter leg's data
      const legLocations = proLocations.filter(({ dispatchId }) => dispatchId === leg.uuid);
      const locationsIds = legLocations.map(({ uuid }) => uuid);

      // Leg requirements
      const legRequirements = requirements.filter(
        ({ dispatchLocationId }) => locationsIds.includes(dispatchLocationId),
      );

      // Check if leg has a drop location
      const isDrop = legLocations.some(({ actionType }) => actionType.toLowerCase().includes('drop'));

      // Leg with its data
      const resultLeg = {
        ...leg, isDrop, locations: legLocations, requirements: legRequirements,
      };

      // Do not check subsequence for the fisrt leg
      if (ind === 0) {
        if (isDrop) {
          // Drop leg - one dispatch
          grouped.push([resultLeg]);
        } else {
          // Accumulate for grouping with next legs
          acc.push(resultLeg);
        }
      // Check drop and subsequence for the second and more legs
      } else if (isDrop) {
        // Accumulated legs - one dispatch
        grouped.push([...acc]);
        // Reset accumulated legs
        acc = [];
        // Drop leg - one dispatch
        grouped.push([resultLeg]);
      } else {
        const isConsecutive = leg.legNumber - proLegs[ind - 1].legNumber === 1;
        // If consecutive - accumulate for grouping with next legs
        if (isConsecutive) {
          acc.push(resultLeg);
        } else {
          // Accumulated legs - one dispatch
          grouped.push([...acc]);
          // Accumulate for grouping with next legs
          acc = [resultLeg];
        }
      }
    });

    grouped.push([...acc]);

    // Filter from empty arrays
    const filtered = grouped.filter((legs) => legs.length > 0);
    dispatches.push(...filtered);
  });

  return dispatches;
};

export default buildDispatches;
