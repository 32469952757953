import React from 'react';
import './style.scss';
import SideMenu from './SideMenu';
import AppHeader from './AppHeader';

const Layout = ({ children }) => (
  <div id='app-layout' className='app-layout'>
    <SideMenu pageWrapId='page-wrap' outerContainerId='app-layout' />
    <main id='page-wrap' className='page-wrap'>
      <AppHeader />
      <section className='main-section'>
        {children}
      </section>
    </main>
  </div>
);

export default Layout;
