import axios from 'axios';
import client from './client';
import { makeRequest } from './utils';

export const loginDriver = async (values) => {
  try {
    const { data } = await axios.post('/api/move/Drivers/Login', {
      loginId: values.loginId,
      pinNumber: values.pinNumber,
    });
    return Promise.resolve(data);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
}; 

export const requestPin = async (values) => {
  try {
    const { data } = await client.post('/api/move/Drivers/RequestPin', values);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const submitDeviceDetails = async (values) => {
  axios
    .post('/api/move/Drivers/DeviceDetails', values)
    .catch((error) => {
      console.error('Could not submit device details', error);
    });
};

export const getProfile = (driverUuid) =>
  client
    .get(`/api/move/Drivers/profile/${driverUuid}`)
    .then(({ data }) => data)
    .catch((error) => {
      console.error(error);
    });

export const updateProfile = async (values) => {
  try {
    const { data } = await client.put('/api/move/Drivers/profile', values);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getDisclaimerText = async () => {
  try {
    const { data } = await axios.get('/api/move/Drivers/GetDisclaimer');
    return Promise.resolve(data);
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

export const emailDisclaimerText = (driverUuid) =>
  client
    .patch(`/api/move/Drivers/EmailDisclaimer/${driverUuid}`)
    .then(({ data }) => data)
    .catch((error) => {
      console.error(error);
    });

export const getEnumerations = (values) =>
  client
    .post('/api/move/Drivers/GetEnumerations')
    .then(({ data }) => data)
    .catch((error) => {
      console.error(error);
    });

export const getSyncIntervals = (values) =>
  client
    .get('/api/move/Drivers/GetSyncIntervals')
    .then(({ data }) => data)
    .catch((error) => {
      console.error(error);
    });
