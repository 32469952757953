import React from 'react';
import './style.scss';
import gwiLogo from 'src/../public/images/gwiilogo.png';
import useConnectionStatus from 'shared/../hooks/useConnectionStatus';
// Components
import { Link } from 'react-router-dom';
import { BsHouseDoor } from 'react-icons/bs';
// App version
import NewVersionBanner from './NewVersionBanner';
import useAppVersion from 'shared/../hooks/useAppVersion';
import useAppSettings from '../../../hooks/useAppSettings';

const HomeLink = () => (
  <Link to='/dispatching' style={{ marginLeft: '3.5rem' }}>
    <BsHouseDoor className='text-white text-2xl hover:text-gray-300' />
  </Link>
);

const Logo = () => (
  <Link to='/dispatching'>
    <img src={gwiLogo} style={{ width: '100px', height: 'auto' }} alt='gwii-logo' />
  </Link>
);

const AppHeader = (props) => {
  useAppSettings();
  const { isDisconnected } = useConnectionStatus();
  const { hasNewVersion } = useAppVersion();

  return (
    <div className='flex flex-col bg-white' {...props}>
      <div className='menu-header bg-action-primary-default px-5 py-3' style={{ height: '60px' }}>
        <HomeLink />
        <Logo />
      </div>
      {isDisconnected && (
        <div className='bg-gwii-dark-blue text-white flex text-sm justify-center items-center'>
          <div className='animate-spin border-gray-300 loading-indicator m-2' />
          Connection Lost - Reconnect to continue
        </div>
      )}
      {(!isDisconnected && hasNewVersion) && <NewVersionBanner />}
    </div>
  );
};

export default AppHeader;
