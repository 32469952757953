import client from './client';
import { makeRequest } from '../utils';

export const subscribeToPushNotifications = async (subscription) =>
  makeRequest(client.post('/api/v2/messaging/webPush/subscribe', subscription));

export const getPublicKey = async () => makeRequest(client.get('/api/v2/messaging/webPush/key'));

export const getSubscriptions = async () => makeRequest(client.get('/api/v2/messaging/webPush/subscriptions'));

export const unsubscribeFromPushNotifications = async (id) =>
  makeRequest(client.delete(`/api/v2/messaging/webPush/unsubscribe/${id}`));

export const getCurrentDriverWebPushStatus = async () => makeRequest(client.get('/api/v2/messaging/webPush/status'));
