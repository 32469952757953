import axios from 'axios';
import { QueryClient } from 'react-query';
import localForage from 'localforage';
import store from 'src/redux';
import { setUser } from 'src/redux/reducers/user';
import {
  setConnected,
  setDisconnected,
  setMessagingDisconnected,
  setMessagingConnected,
} from 'src/redux/reducers/common';
import isNil from 'lodash/isNil';
import { logoutDriver } from '../client';

const client = axios.create({
  withCredentials: true,
});

let timerId;

// Add Authorization header auth token
client.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('auth-token');
    if (token) {
      return (({
        ...config,
        headers: {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        },
      }));
    }
    return config;
  },
  (error) => {
    console.error(error);
    Promise.reject(error);
  },
);

// Refresh Auth Token
export const refreshTokenRequest = (token) => client.post('/api/v2/Auth/refresh',
  { refreshToken: token },
  { headers: { 'NO-RETRY': true } });

client.interceptors.response.use(
  (response) => {
    // Messaging connection issue
    if (response.request.responseURL?.endsWith('offline')) {
      store.dispatch(setMessagingDisconnected());
      return Promise.reject(new Error('Messaging Connection Lost'));
    }
    const isMessaging = response.config.baseURL && !response.config.baseURL.startsWith(window.location.origin);
    if (isMessaging) {
      store.dispatch(setMessagingConnected());
    }
    return response;
  },
  (error) => {
    // App connection issue
    if (!error.response) {
      if (!isNil(timerId)) {
        clearTimeout(timerId);
      }
      store.dispatch(setDisconnected());
    } else {
      timerId = setTimeout(() => {
        store.dispatch(setConnected());
      }, 15000);
    }

    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest.headers['NO-RETRY']) {
      originalRequest._retry = true;
      const refreshToken = localStorage.getItem('refresh-token');
      return refreshTokenRequest(refreshToken)
        .then((res) => {
          if (res.status === 200) {
            localStorage.setItem('auth-token', res.data.access_token);
            localStorage.setItem('refresh-token', res.data.refresh_token);
            return Promise.resolve(client(originalRequest));
          }
          return Promise.reject(error);
        })
        .catch((err) => {
          console.error(err);
          // logout user and redirect to access-denied
          const queryClient = new QueryClient();

          store.dispatch(
            setUser({
              loginId: '',
              pinNumber: '',
              name: '',
              authenticated: false,
              acceptedTerms: false,
            }),
          );
          localForage.setItem('gwiMove_user_info', null);
          localStorage.removeItem('gwisession');
          localStorage.removeItem('auth-token');
          localStorage.removeItem('refresh-token');

          logoutDriver({
            driverNumber: localStorage.getItem('driverNumber'),
            reason: 'Session Expired - got Unauthorized response from v2 server',
          }).catch((error) => console.error(error));
          console.log('Session Expired - got Unauthorized response from v2 server');

          window.location = '/access-denied';
          queryClient.clear();

          return Promise.reject(error);
        });
    }
    return Promise.reject(error);
  },
);

export default client;
