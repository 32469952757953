import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers';

// Create browser history to use in the Redux store
const initialState = window.initialReduxState;

const store = configureStore({
  reducer: rootReducer,
  preloadedState: initialState,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: {
      ignoredPaths: ['modal'],
      ignoredActions: ['modal/showModal'],
    },
  }),
});

export default store;
