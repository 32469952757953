import { useSelector, useDispatch } from 'react-redux';
import useQueryWithSince from 'shared/../hooks/useQueryWithSince';
import { getAllDispatchInformation } from 'src/fetch/dispatches';
import { updateAllDispatchInformation } from 'src/redux/reducers/allDispatchInformation';

const getData = (params) => getAllDispatchInformation(params)
  .then(({ dispatches, dispatchLocations, dispatchLocationRequirements }) => ({
    dispatches,
    locations: dispatchLocations,
    requirements: dispatchLocationRequirements,
  }));

const useData = (options) => useQueryWithSince(['allDispatchInformation'], getData, options);

const useAllDispatchInformation = () => {
  const dispatchAction = useDispatch();

  // Stored data
  const stored = useSelector(({ allDispatchInformation }) => allDispatchInformation);

  // Query to get updates
  const onSuccess = (data) => {
    dispatchAction(updateAllDispatchInformation(data));
  };

  const queryState = useData({ onSuccess });

  return { ...queryState, data: stored };
};

export default useAllDispatchInformation;
