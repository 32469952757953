import React from 'react';
import cn from 'classnames';
import Button from 'shared/buttons/Button';

const Loader = () => (
  <div
    className='button-loader border-text-100 border-t-action-secondary-20'
    style={{
      height: '20px',
      width: '20px',
      borderWidth: '3px',
      borderRadius: '50%',
    }}
  />
);

const ActionModal = ({
  name, onClose, closeButtonTitle, children, contentClassName, contentStyle, isLoading, modalStyle,
}) => {
  const innerContentClassName = cn('flex-1 flex flex-col justify-start items-stretch', {
    [contentClassName]: contentClassName, 
  });
  return (
    <div
      className='action-modal fixed top-0 right-0 left-0 bottom-0 flex-1 w-full flex
      flex-col justify-start items-center bg-gray-500/75 overflow-y-auto'
      style={{
        zIndex: 1001,
        padding: '80px 16px 0px 16px',
      }}
    >
      <div
        className='bg-white text-text-120 shadow-modal flex flex-col items-stretch overlap-action-modal'
        style={modalStyle}
      >
        <div
          className='flex justify-between items-baseline px-5 py-3 shadow-md action-modal-header'
        >
          <div className='flex-1 text-lg font-semibold text-left'>{name}</div>
          <Button
            id='cancel-action-button'
            title={closeButtonTitle ?? 'Cancel'}
            mode='link'
            size='small'
            onClick={onClose}
          />
        </div>
        <div className={innerContentClassName} style={{ overflowY: 'auto', ...(contentStyle ?? {}) }}>
          {isLoading ? <Loader /> : children}
        </div>
      </div>
    </div>
  );
};

export default ActionModal;
