import client from './client';
import { makeRequest } from './utils';

export const createConversation = async (values) => {
  try {
    const { data } = await client.post(`/api/move/Messages/CreateConversation`, values);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getFullSizeFile = async (url) => {
  try {
    const encodedUrl = btoa(url);
    const { data } = await client.get(`/api/move/Messages/GetFile/${encodedUrl}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getMessagingUrl = async (userId) => {
  try {
    const { data } = await client.get(`/api/move/messaging/${userId}/url`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};
