import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const AccessRoute = ({ auth, component: Component, ...routeProps }) => {
  return (
    <Route
      exact
      {...routeProps}
      render={props => auth ? <Component {...props} /> : <Redirect to='/access-denied' />}
    />
  );
};

export default AccessRoute;
