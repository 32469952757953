/* eslint-disable react/jsx-filename-extension */
import 'regenerator-runtime/runtime';
import 'core-js/stable';
import React from 'react';
import ReactDOM from 'react-dom';
// redux
import { Provider } from 'react-redux';
import store from './redux';
// components
import App from './App';
// styles
import 'toastr/build/toastr.css';
import 'flatpickr/dist/themes/material_green.css';
import './styles/index.scss';
import '../public/style.css';

const rootElement = document.getElementById('root');

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  rootElement,
);
