import { useSelector } from 'react-redux';
import requestStatuses from 'shared/../constants/requestStatuses';

const useConnectionStatus = () => {
  const connectionStatus = useSelector(({ common: { connectionStatus } }) => connectionStatus);

  return {
    isDisconnected: connectionStatus === requestStatuses.DISCONNECTED,
  };
};

export default useConnectionStatus;
