import React from 'react';
import LoadingSpinner from './LoadingSpinner';
import cn from 'classnames';

const LoadingOverlay = ({ isRelative = false, ...props }) => {
  const loadingClassName = cn('loading-overlay', { relative: isRelative });
  return (
    <div className={loadingClassName}>
      <LoadingSpinner
        className='bg-gwii-dark-blue text-white rounded p-2 flex align-middle'
        {...props}
      />
    </div>
  );
};

export default LoadingOverlay;
