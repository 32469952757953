import { useQuery } from 'react-query';
import { useCallback, useEffect, useState } from 'react';
import useAppSettings from './useAppSettings';

const useQueryWithSince = (queryKeys, request, options = {}) => {
  // Poll interval
  const { pollIntervals: { MoveDispatchPollInterval } } = useAppSettings();

  const defaultOptions = {
    refetchInterval: MoveDispatchPollInterval,
    refetchOnMount: false,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: false,
  };

  // Since param
  const [since, setSince] = useState(null);

  // Request
  const requestCB = useCallback(() => request({ since }), [request, since]);

  const query = useQuery(queryKeys, requestCB, { ...defaultOptions, ...options });

  // Update Since param after refetch
  const { dataUpdatedAt } = query;

  useEffect(() => {
    setSince(new Date(dataUpdatedAt).toISOString());
  }, [dataUpdatedAt]);

  return query;
};

export default useQueryWithSince;
