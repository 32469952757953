/* eslint-disable eqeqeq */
/* eslint-disable no-nested-ternary */
import React, {
  useState, useEffect, lazy, Suspense, useMemo,
} from 'react';
import { useSelector, connect, useDispatch } from 'react-redux';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
  useLocation,
} from 'react-router-dom';
import * as localForage from 'localforage';
import isNil from 'lodash/isNil';
// Components
import Layout from 'shared/layout/Layout';
import AccessRoute from 'shared/router/AccessRoute';
import LoadingOverlay from 'shared/loading/LoadingOverlay';
import { MessagingContextProvider } from '@gulfwinds/ui-components';
import { ModalContainer } from 'shared/layout/Modal';
// Notifications
import WebPushContextProvider from './WebPushContext';
import AppNotificationConfirmation from './AppNotificationConfirmation';
// react-query
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
// redux
import { setMessagingUrl, setUser } from './redux/reducers/user';
// fetch
import v2Client from 'src/fetch/v2/client';
import { getProfileSummary } from 'src/fetch/v2/user';
import { getMessagingUrl as fetchMessagingUrl } from 'src/fetch/messaging';
// Consts
import {
  MOVE_DISPATCHING_V2,
  MOVE_SETTLEMENTS_V2,
  MOVE_MAINTENANCE_REPORTS_V2,
  MOVE_REPAIR_ORDERS_V2,
  MOVE_PROFILE_V2,
  MOVE_DRIVER_COMPLIANCE,
} from 'shared/../constants/featureFlags';

// Pages Lazy Loading
const onError = (error) => {
  if (error?.name === 'ChunkLoadError') {
    window.location.reload();
  }
  throw error;
};

const Login = lazy(() => import(/* webpackChunkName: "login" */ './login').catch(onError));
const Dispatching = lazy(() => import(/* webpackChunkName: "dispatching" */ './dispatching').catch(onError));
const DispatchingV2 = lazy(() => import(/* webpackChunkName: "dispatching_v2" */ './dispatching/v2').catch(onError));
const Messaging = lazy(() => import(/* webpackChunkName: "messaging" */ './messaging').catch(onError));
const Contacts = lazy(() => import(/* webpackChunkName: "contacts" */ './contacts/components').catch(onError));
const Settlements = lazy(() => import(/* webpackChunkName: "settlements" */ './settlements/components').catch(onError));
const SettlementsV2 = lazy(() => import(/* webpackChunkName: "settlements" */ './settlements/v2').catch(onError));
const MaintenanceReports = lazy(
  () => import(/* webpackChunkName: "maintenancereports" */ './maintenancereports/components').catch(onError),
);
const MaintenanceReportsV2 = lazy(
  () => import(/* webpackChunkName: "maintenancereports" */ './maintenancereports/v2').catch(onError),
);
const RepairOrders = lazy(() => import(/* webpackChunkName: "repairorders" */ './repairorders/components')
  .catch(onError));
const RepairOrdersV2 = lazy(() => import(/* webpackChunkName: "repairorders" */ './repairorders/v2')
  .catch(onError));
const IncidentReportMain = lazy(() => import(/* webpackChunkName: "incident" */ './incident/components')
  .catch(onError));
const Profile = lazy(() => import(/* webpackChunkName: "profile" */ './profile/components').catch(onError));
const ProfileV2 = lazy(() => import(/* webpackChunkName: "profile" */ './profile/v2').catch(onError));
const Help = lazy(() => import(/* webpackChunkName: "help" */ './help').catch(onError));
const DriverCompliancePage = lazy(() => import(/* webpackChunkName: "compliance" */ './compliance/v2').catch(onError));

// react-query
const queryClient = new QueryClient();

const newScript = (src) =>
// create a promise for the newScript
  new Promise((resolve, reject) => {
    // create an html script element
    const script = document.createElement('script');
    // set the source of the script element
    script.src = src;
    // set a listener when the script element finishes loading the script
    script.addEventListener('load', () => {
      // resolve if the script element loads
      resolve();
    });
    // set a listener when the script element faces any errors while loading
    script.addEventListener('error', (e) => {
      // reject if the script element has an error while loading the script
      reject(e);
    });
    // append the script element to the body
    document.body.appendChild(script);
  });

const loadTrackingScripts = async (identifier, identityProperties = {}) => {
  try {
    const {
      safetyScore, appcuesId, heapId, ...userProperties
    } = identityProperties;

    // eslint-disable-next-line
    window.heap = window.heap || [], heap.load = function (e, t) { window.heap.appid = e, window.heap.config = t = t || {}; const r = document.createElement('script'); r.type = 'text/javascript', r.async = !0, r.src = `https://cdn.heapanalytics.com/js/heap-${ e }.js`; const a = document.getElementsByTagName('script')[0]; a.parentNode.insertBefore(r, a); for (let n = function (e) { return function () { heap.push([e].concat(Array.prototype.slice.call(arguments, 0))); }; }, p = ['addEventProperties', 'addUserProperties', 'clearEventProperties', 'identify', 'resetIdentity', 'removeEventProperty', 'setEventProperties', 'track', 'unsetEventProperty'], o = 0; o < p.length; o++) { heap[p[o]] = n(p[o]); } };
    heap.load(heapId);

    window.AppcuesSettings = {
      enableURLDetection: true,
    };
    // load the Appcues script
    await newScript(`https://fast.appcues.com/${appcuesId}.js`);

    if (identifier) {
      heap.identify(identifier);
      await window.Appcues.identify(identifier, { ...userProperties, safetyScore });
    }

    // extract the event and user properties
    heap.addUserProperties(userProperties);

    // clear previous cookies and update with new ones
    if (safetyScore) {
      heap.clearEventProperties();
      heap.addEventProperties({ safetyScore });
    }
  } catch (err) {
    console.error('Error whilst authenticating Appcues or Heap', err);
  }
};

const MessagingContext = ({
  children,
  showMessaging,
  auth,
  apiInstance,
  baseURL,
  user,
}) => {
  const { pathname } = useLocation();

  const showContext = useMemo(() => showMessaging
    && auth
    && pathname !== '/'
    && pathname !== '/access-denied'
    && pathname !== '/messaging', [auth, showMessaging, pathname]);

  if (showContext) {
    return (
      <MessagingContextProvider
        mode='driver'
        apiInstance={apiInstance}
        baseURL={baseURL}
        user={user}
      >
        {children}
      </MessagingContextProvider>
    );
  }

  return children;
};

const App = ({ user }) => {
  const dispatchAction = useDispatch();

  const storedUser = useSelector((state) => state.user);

  const { authenticated: auth, featureFlags = {} } = storedUser;

  // Routes
  const routes = useMemo(() => [
    { path: '/dispatching', component: featureFlags[MOVE_DISPATCHING_V2] ? DispatchingV2 : Dispatching },
    { path: '/messaging', component: Messaging },
    { path: '/contacts', component: Contacts },
    {
      path: '/settlements',
      component: featureFlags[MOVE_SETTLEMENTS_V2] ? SettlementsV2 : Settlements,
    },
    {
      path: '/maintenance-reports',
      component: featureFlags[MOVE_MAINTENANCE_REPORTS_V2] ? MaintenanceReportsV2 : MaintenanceReports,
    },
    {
      path: '/repair-orders',
      component: featureFlags[MOVE_REPAIR_ORDERS_V2] ? RepairOrdersV2 : RepairOrders,
    },
    { path: '/claims-reporting', component: IncidentReportMain },
    {
      path: '/profile',
      component: featureFlags[MOVE_PROFILE_V2] ? ProfileV2 : Profile,
    },
    { path: '/help', component: Help },
    ...(featureFlags[MOVE_DRIVER_COMPLIANCE] ? [{ path: '/compliance', component: DriverCompliancePage }] : []),
  ], [featureFlags]);

  const messagingUrl = useSelector(({ user }) => user.messagingUrl);

  const [showMessaging, setShowMessaging] = useState(false);
  const [loadingUser, setLoadingUser] = useState(true);
  const [loadingMessaging, setLoadingMessaging] = useState(true);

  const getMessagingUrl = (driverId) => fetchMessagingUrl(driverId)
    .then((result) => {
      const hasToken = !isNil(localStorage.getItem('auth-token'))
        && localStorage.getItem('auth-token') !== 'undefined'
        && !isNil(result);
      dispatchAction(setMessagingUrl(result));
      setShowMessaging(hasToken);
      setLoadingMessaging(false);
    })
    .catch(() => {
      setLoadingMessaging(false);
    });

  const getUser = async () => {
    try {
      const authObject = await localForage.getItem('gwiMove_user_info');

      if (authObject && authObject.authenticated) {
        dispatchAction(setUser(authObject));
        getMessagingUrl(authObject.loginId);

        if (authObject.heapIdentity && authObject.loginId) {
          loadTrackingScripts(authObject.loginId, authObject.heapIdentity);
        }

        if (authObject.featureFlags?.[MOVE_PROFILE_V2]) {
          const profileSummary = await getProfileSummary();
          dispatchAction(setUser({
            ...profileSummary,
            photo: {
              fullSizeUrl: profileSummary.profilePictureUrl,
            },
          }));
        }
      }

      setLoadingUser(false);
    } catch (error) {
      console.log('error', error);
      setLoadingUser(false);
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <Router>
      <MessagingContext
        auth={auth}
        showMessaging={showMessaging}
        apiInstance={v2Client}
        baseURL={messagingUrl}
        user={user}
      >
        <QueryClientProvider client={queryClient}>
          <Suspense fallback={<LoadingOverlay />}>
            <Switch>
              <Route exact path='/access-denied' component={Login} />
              <Route
                exact
                path='/'
                render={(props) => (auth ? <Redirect to='/dispatching' /> : <Login {...props} />)}
              />
              <Layout>
                {loadingUser || loadingMessaging ? (
                  <div className=''>
                    <LoadingOverlay />
                  </div>
                ) : (
                  <WebPushContextProvider>
                    <AppNotificationConfirmation auth={auth} />
                    <Switch>
                      {routes.map((r) => (
                        <AccessRoute exact={r.exact} key={r.path} path={r.path} auth={auth} component={r.component} />
                      ))}
                    </Switch>
                  </WebPushContextProvider>
                )}
              </Layout>
            </Switch>
          </Suspense>
          <ReactQueryDevtools initialIsOpen={false} />
          <ModalContainer />
        </QueryClientProvider>
      </MessagingContext>
    </Router>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps, {})(App);
