/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loginId: '',
  pinNumber: '',
  authenticated: false,
  acceptedTerms: false,
  email: '',
  name: '',
  messagingUrl: null,
  heap: {},
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, { payload }) => ({ ...state, ...payload }),
    setMessagingUrl: (state, { payload }) => ({ ...state, messagingUrl: payload }),
  },
});

export const { setUser, setMessagingUrl } = userSlice.actions;
export default userSlice.reducer;
