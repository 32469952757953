import React from 'react';
import cn from 'classnames';
import './style.scss';

const SmallLoader = ({ className }) => {
  const loaderClassName = cn('button-loader border-text-100 border-t-action-secondary-20', {
    [className]: !!className,
  });
  return (
    <div
      className={loaderClassName}
      style={{
        height: '18px',
        width: '18px',
        borderWidth: '3px',
        borderRadius: '50%',
      }}
    />
  );
};

export default SmallLoader;
