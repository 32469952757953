/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { differenceBy } from 'lodash';
import { CANCELLED_STATUS, REJECTED_STATUS } from 'src/dispatching/consts';

const initialState = {
  isLoaded: false,
  dispatches: [],
  locations: [],
  requirements: [],
};

const allDispatchInformationSlice = createSlice({
  name: 'allDispatchInformation',
  initialState,
  reducers: {
    updateRequirement: ({ requirements, ...state }, { payload }) => {
      const ind = requirements.findIndex((s) => s.uuid === payload.uuid);
      if (ind < 0) {
        return { ...state, requirements: [...requirements, payload] };
      }
      const result = requirements;
      result[ind] = payload;
      return { ...state, requirements: result };
    },
    deleteRequirement: ({ requirements, ...state }, { payload }) => ({
      ...state,
      requirements: requirements.filter((s) => s.uuid !== payload.uuid),
    }),
    cleanRequirements: (state) => {
      const { locations, requirements, isLoaded } = state;

      if (!isLoaded) {
        return state;
      }

      const locationsIds = locations.map(({ uuid }) => uuid);
      const filtered = requirements.filter(({ dispatchLocationId }) => locationsIds.includes(dispatchLocationId));

      return { ...state, requirements: filtered };
    },
    updateDispatchInformation: (state, { payload: { dispatches, locations = [], proNumber = null } }) => {
      // If pro number is passed but no dispatches - remove all
      if (proNumber && (!dispatches || dispatches.length === 0)) {
        const filtered = state.dispatches.filter((d) => d.proNumber !== proNumber);
        const filteredIds = filtered.map(({ uuid }) => uuid);
        return {
          ...state,
          dispatches: filtered,
          locations: state.locations.filter(({ dispatchId }) => filteredIds.includes(dispatchId)),
        };
      }

      // Filter from cancelled and rejected
      const filteredDispatches = dispatches
        .filter(({ status }) => (status !== CANCELLED_STATUS && status !== REJECTED_STATUS));

      const filteredDispatchesIds = filteredDispatches.map(({ uuid }) => uuid);

      const filteredLocations = locations
        .filter(({ dispatchId }) => filteredDispatchesIds.includes(dispatchId));

      return {
        ...state,
        dispatches: [...differenceBy(state.dispatches, dispatches, 'uuid'), ...filteredDispatches],
        locations: [...differenceBy(state.locations, locations, 'uuid'), ...filteredLocations],
      };
    },
    updateAllDispatchInformation: (state, { payload: updates }) => {
      const {
        dispatches: storedDispatches,
        locations: storedLocations,
        requirements: storedRequirements,
        isLoaded,
      } = state;

      // On initial loading
      if (!isLoaded) {
        return { isLoaded: true, ...updates };
      }

      // Updates
      const {
        dispatches: updatedDispatches, locations: updatedLocations, requirements: updatedRequirements,
      } = updates;

      // Keep stored if not updates recieved
      const toKeepDispatches = differenceBy(storedDispatches, updatedDispatches, 'uuid');
      const toKeepLocations = differenceBy(storedLocations, updatedLocations, 'uuid');

      // Add updates by status
      const toAddDispatches = updatedDispatches.filter(({ status }) => (
        status !== CANCELLED_STATUS && status !== REJECTED_STATUS));

      // Resulting dispatches
      const dispatches = [...toKeepDispatches, ...toAddDispatches];
      const dispatchesIds = dispatches.map(({ uuid }) => uuid);

      // Locations changes
      const locations = [...toKeepLocations, ...updatedLocations]
        .filter(({ dispatchId }) => dispatchesIds.includes(dispatchId));

      // Requirements changes
      const toKeepRequirements = differenceBy(storedRequirements, updatedRequirements, 'uuid');

      // DO NOT filter requirement here as a completed dispatch can use it
      const requirements = [...toKeepRequirements, ...updatedRequirements];

      return {
        ...state, dispatches, locations, requirements,
      };
    },
  },
});

export const {
  updateRequirement, deleteRequirement, cleanRequirements, updateDispatchInformation, updateAllDispatchInformation,
} = allDispatchInformationSlice.actions;

export default allDispatchInformationSlice.reducer;
