import { useEffect, useState, useRef } from 'react';
import isNil from 'lodash/isNil';
import useGroupedDispatches from 'src/dispatching/hooks/useGroupedDispatches';

const usePrevious = (value) => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

// Diff'ing by uuids
const getDifference = (dispatches, prevDispatches) => dispatches
  .filter((dispatch) => isNil(
    prevDispatches.find((oldDispatch) => (dispatch.uuid === oldDispatch.uuid)),
  ));

const defaultState = { hasNewDispatches: false, newDispatchesCount: 0 };

const useNewDispatchesStatus = () => {
  const { data, isSuccess } = useGroupedDispatches();
  const prevData = usePrevious(data);

  const [newDispatchesStatus, setNewDispatchesStatus] = useState(defaultState);

  useEffect(() => {
    if (isSuccess && prevData && data) {
      const { available } = data;
      const { available: prevAvailable } = prevData;
      const newAvailable = getDifference(available, prevAvailable);

      const prevAvailableCount = prevAvailable.length;
      const newDispatchesCount = newAvailable.length;

      const hasNewDispatches = prevAvailableCount > 0 && newDispatchesCount > 0;

      setNewDispatchesStatus({ hasNewDispatches, newDispatchesCount });
    }
  }, [data, prevData, isSuccess]);

  return newDispatchesStatus;
};

export default useNewDispatchesStatus;
