/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = [];

const dispatchesSlice = createSlice({
  name: 'dispatches',
  initialState,
  reducers: {
    setDispatches: (_, { payload }) => payload,
    updateDispatchById: (state, { payload }) => {
      const { id } = payload;
      return [...state.filter((item) => item.id !== id), payload];
    },
    removeDispatchById: (state, { payload }) => state.filter(({ id }) => id !== payload),
  },
});

export const {
  setDispatches, updateDispatchById, removeDispatchById,
} = dispatchesSlice.actions;

export default dispatchesSlice.reducer;
