import { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import requestStatuses from 'shared/../constants/requestStatuses';
import isNil from 'lodash/isNil';

const useMessagingConnectionStatus = () => {
  const messagingConnectionStatus = useSelector(({ common }) => common.messagingConnectionStatus);
  const [isDisconnected, setIsDisconnected] = useState(false);
  const timer = useRef();

  useEffect(() => {
    if (messagingConnectionStatus === requestStatuses.DISCONNECTED) {
      if (!isNil(timer.current)) {
        clearTimeout(timer.current);
      }
      timer.current = null;
      setIsDisconnected(true);
    } else if (isNil(timer.current)) {
      timer.current = setTimeout(() => {
        setIsDisconnected(false);
        timer.current = null;
      }, 30000);
    }
  }, [messagingConnectionStatus]);

  return { isMessagingDisconnected: isDisconnected };
};

export default useMessagingConnectionStatus;
