import { useEffect, useState } from 'react';
import useDispatches from './useDispatches';
import groupDispatchesByStatus from '../selectors/groupDispatchesByStatus';

const defaultValue = {
  available: [],
  inProgress: [],
  completed: [],
};

const useGroupedDispatches = () => {
  const { data: dispatches, ...rest } = useDispatches();
  const { isSuccess } = rest;

  const [data, setData] = useState(defaultValue);

  useEffect(() => {
    if (isSuccess) {
      const grouped = groupDispatchesByStatus(dispatches);
      setData(grouped);
    }
  }, [isSuccess, dispatches]);

  return { ...rest, data };
};

export default useGroupedDispatches;
