import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { getBEVersion } from 'src/fetch/app';
import { setLastBuildDate } from 'src/redux/reducers/common';
import useAppSettings from './useAppSettings';

const QUERY_KEY = 'appVersion';

const useAppVersion = () => {
  const dispatchAction = useDispatch();
  const hasNewVersion = useSelector(({ common: { hasNewVersion } }) => hasNewVersion);
  const { pollIntervals } = useAppSettings();

  useQuery([QUERY_KEY], getBEVersion, {
    refetchInterval: pollIntervals.MoveSettingsPollInterval,
    refetchOnMount: true,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: true,
    onSuccess: (newBuildDate) => {
      dispatchAction(setLastBuildDate(newBuildDate));
    },
  });

  return useMemo(() => ({ hasNewVersion }), [hasNewVersion]);
};

export default useAppVersion;
