import { useEffect, useState } from 'react';
import useAllDispatchInformation from './useAllDispatchInformation';
import { buildDispatches, buildDispatchData } from '../selectors';

const defaulState = [];

const useDispatches = () => {
  const { data: allDispatchInformation, ...rest } = useAllDispatchInformation();
  const { isSuccess } = rest;

  const [data, setData] = useState(defaulState);

  useEffect(() => {
    if (isSuccess) {
      const builtDispatches = buildDispatches(allDispatchInformation);
      const result = builtDispatches.map(buildDispatchData);
      setData(result);
    }
  }, [isSuccess, allDispatchInformation]);

  return { ...rest, data };
};

export default useDispatches;
