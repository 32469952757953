import React, { createContext } from 'react';
import useWebPushSubscriptions from './shared/hooks/useWebPushSubscription';

export const WebPushContext = createContext('web-push-context');

const WebPushContextProvider = ({ children }) => {
  const webPushData = useWebPushSubscriptions();

  return (
    <WebPushContext.Provider value={webPushData}>
      {children}
    </WebPushContext.Provider>
  );
};

export default WebPushContextProvider;
