import client from './client';
import { makeRequest, getQueryParams } from './utils';

export const getAllDispatchInformation = (params) => makeRequest(
  client.post(`/api/move/Dispatches/GetAllDispatchInformation${getQueryParams(params)}`),
);

export const getDispatches = (params) => makeRequest(
  client.post(`/api/move/Dispatches/GetDispatches${getQueryParams(params)}`),
);

export const getDispatchesByProNumber = (proNumber) => makeRequest(
  client.get(`/api/move/Dispatches/GetDispatches/${proNumber}`),
);

export const removeDispatchFlag = async (values) => {
  try {
    const { data } = await client.patch(`/api/move/Dispatches/${values.uuid}/RemoveFlag`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const acceptDispatch = ({ uuid }) => makeRequest(
  client.patch(`/api/move/Dispatches/${uuid}/Accept`),
);

export const declineDispatch = (values) => {
  const body = {
    statusReason: values.statusReason,
    statusComments: values.statusComments,
  };
  return makeRequest(
    client.patch(`/api/move/Dispatches/${values.uuid}/Decline`, body),
  );
};

export const startDispatch = async ({ uuid }) => makeRequest(
  client.patch(`/api/move/Dispatches/${uuid}/Start`),
);

export const createDualTransaction = ({ dispatchId, nextDispatchId }) => makeRequest(
  client.patch(`/api/move/Dispatches/${dispatchId}/CreateDualTransaction?nextDispatchUuid=${nextDispatchId}`),
);

export const cancelDualTransaction = ({ dispatchId, nextDispatchId }) => makeRequest(
  client.patch(`/api/move/Dispatches/${dispatchId}/CancelDualTransaction?nextDispatchUuid=${nextDispatchId}`),
);

// Dispatch Locations
export const getDispatchLocations = (params) => makeRequest(
  client.get(`/api/move/DispatchLocations${getQueryParams(params)}`),
);

export const getDispatchLocationsByProNumber = (pro) => makeRequest(
  client.get(`/api/move/DispatchLocations/${pro}`),
);

export const arriveToDispatchLocation = ({ location, value }) => {
  const body = { ...location, arrivedAt: value };
  return makeRequest(
    client.put(`/api/move/DispatchLocations/${location.uuid}/Arrived`, body),
  );
};

export const departFromDispatchLocation = ({ location, value }) => {
  const body = { ...location, departedAt: value };
  return makeRequest(
    client.put(`/api/move/DispatchLocations/${location.uuid}/Departed`, body),
  );
};

export const getDispatchLocationRequirements = (params) => makeRequest(
  client.get(`/api/move/DispatchLocations/Requirements${getQueryParams(params)}`),
);

export const getDispatchLocationRequirement = (values) => makeRequest(
  client.get(`/api/move/DispatchLocations/Requirements/${values.uuid}`),
);

export const createInspection = (values) => makeRequest(
  client.post('/api/move/DispatchLocations/Requirements/Inspection', values),
);

export const deleteRequirement = (values) => makeRequest(
  client.delete(`/api/move/DispatchLocations/Requirements/${values.uuid}`),
);

export const createInterchange = (values) => makeRequest(
  client.post('/api/move/DispatchLocations/Requirements/Interchange', values),
);

export const createScaleTicket = (values) => makeRequest(
  client.post('/api/move/DispatchLocations/Requirements/ScaleTicket', values),
);

export const createPod = (values) => makeRequest(
  client.post('/api/move/DispatchLocations/Requirements/Pod', values),
);

export const createBol = (values) => makeRequest(
  client.post('/api/move/DispatchLocations/Requirements/Bol', values),
);

export const requestDispatch = () => makeRequest(
  client.get('/api/move/Dispatches/RequestMore'),
);

export const getPickupFromYard = (dispatchId) => makeRequest(
  client.get(`/api/move/Dispatches/${dispatchId}/CheckPickupAvailable`),
);

export const confirmPickupFromYard = ({ dispatchId, equipment }) => makeRequest(
  client.post(`/api/move/Dispatches/${dispatchId}/ConfirmPickupFromYard`, { equipment }),
);

export const getPrinterLocations = () => makeRequest(
  client.get('/api/move/Dispatches/PrinterLocations'),
);

export const printOrder = ({ dispatchId, locationName }) => makeRequest(
  client.get(`/api/move/Dispatches/${dispatchId}/PrintOrder?location=${encodeURIComponent(locationName)}`),
);

export const emailOrder = (uuid, email) => makeRequest(
  client.get(`/api/move/Dispatches/${uuid}/EmailOrder?email=${encodeURIComponent(email)}`),
);

export const matchChassisNumber = (chassisNumber) => makeRequest(
  client.get(`/api/move/Equipment/chassis/${chassisNumber}`),
);
