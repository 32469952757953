// state hook to manage web push subscriptions
import { useEffect, useState } from 'react';
import {
  getCurrentDriverWebPushStatus,
  getPublicKey,
  subscribeToPushNotifications,
} from '../../fetch/v2/messagingWebPush';
import { useLocation, useHistory } from 'react-router-dom';
import { ClientJS } from 'clientjs';
import localforage from 'localforage';

import { MESSAGING_ANNOUNCEMENTS } from 'src/shared/constants/featureFlags';

// consts
export const NotificationStatus = {
  GRANTED: 'granted',
  DENIED: 'denied',
  DEFAULT: 'default',
  ERROR: 'error',
  LOADING: 'loading',
};

export const ConversationTransport = {
  Web: 0,
  Text: 1,
};

// helpers
function arrayBufferToBase64(buffer) {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}

const client = new ClientJS();

// checkers
const isWebPushSupported = 'serviceWorker' in navigator && 'PushManager' in window;
const NotificationPermission = isWebPushSupported ? Notification.permission : null;

// State hook
const useWebPushSubscriptions = () => {
  // States
  const [status, setStatus] = useState(null);
  const [currentDevice, setCurrentDevice] = useState(null);
  const [askingForNotificationPermissionModalOpen, setAskingForNotificationPermissionModalOpen] = useState(false);
  const [announcementsEnabled, setAnnouncementsEnabled] = useState(false);

  // location hook
  const { pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    const checkAnnouncementsFeature = async () => {
      try {
        const driver = await localforage.getItem('gwiMove_user_info');
        if (driver) {
          const isEnabled = driver.featureFlags?.[MESSAGING_ANNOUNCEMENTS] ?? false;
          setAnnouncementsEnabled(isEnabled);
        } else {
          setAnnouncementsEnabled(false);
        }
      } catch (e) {
        console.error('error checking announcements feature', e);
        setAnnouncementsEnabled(false);
      }
    };
    checkAnnouncementsFeature();
  }, []);

  // this is used to ask the user for permission
  const displayAskPermissionModal = async () => {
    if (!isWebPushSupported || !announcementsEnabled) {
      return;
    }

    setAskingForNotificationPermissionModalOpen(true);
    setStatus(NotificationStatus.DEFAULT);
  };

  // actually display the system prompt
  const showSystemPrompt = async () => {
    const permission = await Notification.requestPermission();
    setAskingForNotificationPermissionModalOpen(false);
    if (permission === 'granted') {
      setStatus(NotificationStatus.GRANTED);
    }
    if (permission === 'denied') {
      setStatus(NotificationStatus.DENIED);
    }
    confirmSubscription();

  };
  // rest of file should change
  // if sub setup -> confirm with be -> if bad restore
  // if not -> restore

  // restore -> create new like you dont care! since this didnt match any in BE so no duplicates anyway
  const confirmSubscription = async () => {
    // not supported -> return
    if (!isWebPushSupported || !announcementsEnabled) {
      return;
    }
    if (Notification.permission === 'denied') {
      setStatus(NotificationStatus.DENIED);
      return;
    }

    // if default - ask for permission
    if (Notification.permission === 'default') {
      setStatus(NotificationStatus.DEFAULT);
      // if profile page -> return
      if (pathname.includes('/profile')) {
        return;
      }
      displayAskPermissionModal();
      return;
    }

    // otherwise we have permission
    setAskingForNotificationPermissionModalOpen(false);

    setStatus(NotificationStatus.LOADING);

    // get registration
    const registration = await navigator.serviceWorker.getRegistration();

    // if no registration -> return
    if (!registration) {
      setStatus(NotificationStatus.ERROR);
      return;
    }

    const subscription = await registration.pushManager.getSubscription();

    // ok now we can confirm

    // default to requiring restore
    let restoreRequired = true;

    // then try to match
    if (subscription) {
      const status = await getCurrentDriverWebPushStatus();

      const { activeSubscriptions: devices, requiresSubscription } = status;

      // try matching using fingerprint
      const fingerprint = client.getFingerprint();

      let device = null;
      const matchingSubs = devices.filter(
        (d) => d.deviceUuid == fingerprint && d.endpointLast5 === subscription.endpoint.slice(-5),
      );

      if (matchingSubs.length < 1) {
        device = null;
      } else {
        device = matchingSubs[0];
      }
      if (device) {
        restoreRequired = false;
        setCurrentDevice(device);
      }
    }

    if (restoreRequired) {
      // unsub
      if (subscription) {
        await subscription.unsubscribe();
      }

      // lets just create a new one
      const publicKey = await getPublicKey();

      let newSubscription = null;
      try {
        const subDetails = { userVisibleOnly: true, applicationServerKey: publicKey };

        // sometimes not resolving, lets abort after 10 seconds
        const subPromise = registration.pushManager.subscribe(subDetails);
        const timeoutPromise = new Promise((resolve) => setTimeout(() => resolve(null), 10000));

        newSubscription = await Promise.race([subPromise, timeoutPromise]);

        if (!newSubscription) {
          throw new Error('subscription timed out');
        }
      } catch (e) {
        console.error('error subscribing', e);
        setStatus(NotificationStatus.ERROR);
        setCurrentDevice(null);
        return;
      }

      const newDevice = await subscribeToPushNotifications({
        deviceUuid: client.getFingerprint(),
        endpoint: newSubscription.endpoint,
        auth: arrayBufferToBase64(newSubscription.getKey('auth')),
        p256dh: arrayBufferToBase64(newSubscription.getKey('p256dh')),
      });
      setCurrentDevice(newDevice);
    }
    setStatus(NotificationStatus.GRANTED);
  };

  // loop check
  useEffect(() => {
    const timer = setTimeout(
      () => {
        confirmSubscription();
      }, // 10 minutes
      600000,
    );
    return () => clearTimeout(timer);
  }, [NotificationPermission, status, announcementsEnabled]);

  // first check
  useEffect(() => {
    // after 3 seconds, do first check
    new Promise((resolve) => setTimeout(resolve, 3000)).then(() => {
      confirmSubscription();
    });
  }, [announcementsEnabled, NotificationPermission]);

  const redirectToProfile = () => {
    history.push('/profile');
  };

  return {
    status,
    actions: {
      showSystemPrompt,
      // onDeviceConfirmation,
      // revalidateSubscription: checkForSubscription,
      askForNotificationPermission: displayAskPermissionModal,
      redirectToProfile,
    },
    modals: {
      askingForNotificationPermissionModalOpen,
      // askingForDeviceConfirmationModalOpen,
    },
    currentDevice,
    isWebPushSupported,
    announcementsEnabled,
  };
};

export default useWebPushSubscriptions;
