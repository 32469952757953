import React from 'react';
import './style.scss';

const LoadingSpinner = ({ text, ...props }) => (
  <div
    className='z-50 fixed top-0 right-0 bg-gwii-dark-blue text-white rounded p-2 m-2 flex align-middle'
    {...props}
  >
    <div className='button-loader border-gray-300 loading-indicator mr-2' />
    {text !== null && <span className='loading-indicator-text'>{text || 'Loading...'}</span>}
  </div>
);
 
export default LoadingSpinner;
