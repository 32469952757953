import React from 'react';
import { BsInfoCircle } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const Breadcrumbs = ({ paths }) => paths.map(({ path, name, active }, ind) => (
  <div className='flex' key={path ?? name}>
    {ind > 0 && <span className='text-gray-300 font-normal mx-1'>/</span>}
    {active && <span className='flex'>{name}</span>}
    {!active && (
      <Link
        to={`/${path}`}
        className='flex text-text-100 font-normal hover:text-text-120'
      >
        {name}
      </Link>
    )}
  </div>
));

const PageHeader = ({
  title,
  children,
  navigational = false,
  paths = [],
  helpAnchor,
  ...props
}) => {
  const content = title ?? children;

  if (!content && !navigational) {
    return null;
  }

  return (
    <div
      className='flex gap-1 flex-wrap items-center bg-white text-2xl font-bold text-text-120 px-5 pt-2'
      style={{ minHeight: '50px' }}
      {...props}
    >
      {!navigational && content}
      {navigational && <Breadcrumbs paths={paths} />}
      {helpAnchor && (
        <Link
          id='help-icon-link'
          className='ml-auto'
          to={`/help#${helpAnchor}`}
          title='Go to help page'
        >
          <BsInfoCircle />
        </Link>
      )}
    </div>
  );
};

export default PageHeader;
