import React from 'react';
import Button from 'shared/buttons/Button';
import { BsArrowRepeat } from 'react-icons/bs';

const RefreshButton = () => (
  <Button
    title='Update Now'
    icon={<BsArrowRepeat />}
    color='tertiary'
    onClick={() => window.location.reload()}
  />
);

const NewVersionBanner = () => (
  <div className='flex items-center py-3 px-5 rounded my-1 mx-2 bg-primary-transparent'>
    <div className='mr-5 text-md font-semibold text-primary-60'>
      New app version available. Please refresh for updates
    </div>
    <RefreshButton />
  </div>
);

export default NewVersionBanner;
