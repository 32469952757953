import React, { useContext } from 'react';
import Button from 'shared/buttons/Button';
import MenuModal from 'shared/layout/Modal/MenuModal';

import { WebPushContext } from './WebPushContext';

const AppNotificationConfirmation = () => {
  // get from context provider
  const context = useContext(WebPushContext);

  const {
    actions: { showSystemPrompt },
    modals: { askingForNotificationPermissionModalOpen },
  } = context;

  return (
    <div className='notification-confirm'>
      {askingForNotificationPermissionModalOpen && (
        <MenuModal title='This app uses notifications to keep you updated. Please "Allow" notifications to continue.'>
          <div className='flex mt-1 gap-2 justify-center w-full'>
            <Button
              title='Allow Notifications'
              onClick={async (e) => {
                showSystemPrompt();
              }}
              type='button'
              color='success'
              fluid
            />
          </div>
        </MenuModal>
      )}

    </div>
  );
};

export default AppNotificationConfirmation;