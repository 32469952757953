import { combineReducers } from '@reduxjs/toolkit';
import allDispatchInformation from './allDispatchInformation';
import common from './common';
import dispatches from './dispatches';
import modal from './modal';
import user from './user';

const rootReducer = combineReducers({
  allDispatchInformation,
  common,
  dispatches,
  modal,
  user,
});

export default rootReducer;
