/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import requestStatuses from 'shared/../constants/requestStatuses';

const initialState = {
  connectionStatus: requestStatuses.INIT,
  messagingConnectionStatus: requestStatuses.INIT,
  lastBuildDate: null,
  hasNewVersion: false,
  pollIntervals: {
    // milliseconds
    MoveDispatchPollInterval: 60 * 1000,
    MoveSettingsPollInterval: 600 * 1000,
  },
};

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    setDisconnected: (state) => ({ ...state, connectionStatus: requestStatuses.DISCONNECTED }),
    setConnected: (state) => (state.connectionStatus === requestStatuses.DISCONNECTED
      ? { ...state, connectionStatus: requestStatuses.INIT }
      : state),
    setMessagingDisconnected: (state) => ({ ...state, messagingConnectionStatus: requestStatuses.DISCONNECTED }),
    setMessagingConnected: (state) => (state.messagingConnectionStatus === requestStatuses.DISCONNECTED
      ? { ...state, messagingConnectionStatus: requestStatuses.INIT }
      : state),
    setLastBuildDate: (state, { payload: newValue }) => {
      const { lastBuildDate, hasNewVersion } = state;
      const needUpdate = lastBuildDate && lastBuildDate !== newValue && !hasNewVersion;

      if (needUpdate) {
        return { ...state, lastBuildDate: newValue, hasNewVersion: true };
      }
      return { ...state, lastBuildDate: newValue };
    },
    setPollIntervals: (state, { payload: newValue }) => ({
      ...state,
      pollIntervals: { ...state.pollIntervals, ...newValue },
    }),
  },
});

export const {
  setDisconnected, setConnected, setMessagingDisconnected, setMessagingConnected, setLastBuildDate, setPollIntervals,
} = commonSlice.actions;

export default commonSlice.reducer;
