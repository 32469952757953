import axios from 'axios';
import { QueryClient } from 'react-query';
import localForage from 'localforage';
import store from 'src/redux';
import { setConnected, setDisconnected } from 'src/redux/reducers/common';
import { setUser } from 'src/redux/reducers/user';
import isNil from 'lodash/isNil';
import toastr from 'shared/helpers/toastr';

const client = axios.create({
  withCredentials: true,
});

// logout driver event -> here due to circular dependency
export const logoutDriver = async (values) => {
  try {
    await client.post('/api/move/Drivers/Logouts', values);
    return Promise.resolve();
  } catch (error) {
    console.error(error);
    return Promise.reject(error);
  }
};

let timerId;

// Set a gwisession on requests that axios makes
client.interceptors.request.use(
  (config) => {
    config.headers.Authorization = localStorage.getItem('gwisession');
    return config;
  },
  (error) => {
    console.error(error);
    Promise.reject(error);
  },
);

client.interceptors.response.use(
  (response) => response,
  (error) => {
    // If no V1 session id - show error and logout
    const v1Session = localStorage.getItem('gwisession');
    const isEmptyV1Session = isNil(v1Session) || v1Session === 'null' || v1Session === '' || v1Session === 'undefined';

    if (isEmptyV1Session) {
      toastr('error', 'Invalid token');
    }

    // App connection issue
    if (!error.response) {
      if (!isNil(timerId)) {
        clearTimeout(timerId);
      }
      store.dispatch(setDisconnected());
    } else {
      timerId = setTimeout(() => {
        store.dispatch(setConnected());
      }, 15000);
    }
    if (error.response?.status === 401 || isEmptyV1Session) {
      // logout user and redirect to access-denied
      const queryClient = new QueryClient();

      // Clean user info from redux storage
      store.dispatch(
        setUser({
          loginId: '',
          pinNumber: '',
          name: '',
          authenticated: false,
          acceptedTerms: false,
        }),
      );

      let sessDetails = {};

      try {
        if (!isEmptyV1Session) {
          // semicolon separated
          const sessArr = v1Session.split(';');
          sessArr.forEach((item) => {
            const itemArr = item.split('=');

            if (!itemArr || itemArr.length !== 2) {
              return;
            }

            const key = itemArr[0].trim();
            const value = itemArr[1].trim();

            let expiresDate = null;

            switch (key) {
            case 'GWIAPI_driveraccess':
              sessDetails.authLast4 = value.slice(-4);
              break;
            case 'expires':
              expiresDate = new Date(value);
              sessDetails.expires = expiresDate.toISOString();
              break;
            default:
              sessDetails[key] = value;
            }

            sessDetails[key] = value;
          });
        }
      } catch (err) {
        console.error(err);
        sessDetails = {};
      }

      // Fire and forget
      logoutDriver({
        driverNumber: localStorage.getItem('driverNumber'),
        reason: isEmptyV1Session ? 'Invalid v1 token' : 'Session Expired - got Unauthorized response from server',
        attemptedEndpoint: error.config.url,
        ...sessDetails,
      }).catch((error) => console.error(error));

      if (isEmptyV1Session) {
        console.error('Invalid v1 token');
      } else {
        console.error('Session Expired - got Unauthorized response from server');
      }

      localForage.setItem('gwiMove_user_info', null);
      localStorage.removeItem('gwisession');
      localStorage.removeItem('auth-token');
      localStorage.removeItem('refresh-token');

      window.location = '/access-denied';
      queryClient.clear();
    } else {
      return Promise.reject(error);
    }
  },
);

export default client;
