import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { hideModal } from 'src/redux/reducers/modal';

const ModalContainer = () => {
  const dispatchAction = useDispatch();
  const { modal, props } = useSelector(({ modal }) => modal);

  // On Browser back/forward event - close modal
  useEffect(() => {
    const handleEvent = () => {
      dispatchAction(hideModal());
    };

    window.addEventListener('popstate', handleEvent);
    return () => window.removeEventListener('popstate', handleEvent);
  }, []);

  if (!modal) {
    return null;
  }

  const Modal = modal;
  return <Modal {...props} />;
};

export default ModalContainer;
