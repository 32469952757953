import { isNil, max, uniq } from 'lodash';
import { format } from 'date-fns';
import buildStops from './buildStops';
import { ARRIVED_STATUS, IN_TRANSIT_STATUS, NOT_ACCEPTED_STATUS } from '../consts';

const getSpecialRequirements = (dispatchLegs, locations) => {
  const specialRequirements = locations
    .map(({ indicators }) => indicators.map((i) => (i.includes('HAZ') ? 'Haz' : i))).flat() ?? [];

  const isHazardous = dispatchLegs.some(({ hazardous }) => hazardous === true);
  const needScale = dispatchLegs.some(({ scale }) => scale === true);

  if (isHazardous) {
    specialRequirements.push('Haz');
  }
  if (needScale) {
    specialRequirements.push('Scale');
  }

  return uniq(specialRequirements);
};

const findDeadlineRemark = (dispatchLegs) => {
  const remarks = dispatchLegs
    .map(({ locations, cutoffDate }) => {
      const deadlineRemarks = locations.find((l) => l.deadlineRemarks)?.deadlineRemarks ?? null;
      if (deadlineRemarks && deadlineRemarks.toLowerCase().includes('cutoff') && cutoffDate) {
        return `CUTOFF ${format(new Date(cutoffDate), 'MMMM Do, YYYY HH:mm')}`;
      }
      return deadlineRemarks;
    })
    .filter((deadlineRemarks) => deadlineRemarks);

  return remarks.length > 0 ? remarks[0] : null;
};

const requirementTextMap = {
  inspection: 'Inspection',
  proofOfDelivery: 'Proof Of Delivery',
  billOfLading: 'Bill Of Lading',
  scaleTicket: 'Scale Ticket',
  inInterchange: 'InEir',
  outInterchange: 'OutEir',
};

const buildDispatchData = (dispatchLegs) => {
  // First leg is used for dispatch common data
  const [firstLeg] = dispatchLegs;

  // Last legs is used for appointment time
  const lastLeg = dispatchLegs[dispatchLegs.length - 1];
  const { appointmentDate, appointmentTime } = lastLeg;

  // Build common data
  const assignmentChanged = dispatchLegs.some((leg) => leg.assignmentChanged);
  const priority = max(dispatchLegs.map(({ priority }) => priority ?? 0));

  // Current active leg - the one where a driver is in progress now
  const currentLeg = dispatchLegs.find(({ status }) => status === NOT_ACCEPTED_STATUS)
    ?? dispatchLegs.find(({ status }) => status === ARRIVED_STATUS)
    ?? dispatchLegs.find(({ status }) => status === IN_TRANSIT_STATUS)
    ?? dispatchLegs[dispatchLegs.length - 1];

  // Dispatch status = current leg's status
  const dispatchStatus = currentLeg?.status;

  // Build stops
  const locations = dispatchLegs.map(({ locations }) => locations).flat();
  const requirements = dispatchLegs.map(({ requirements }) => requirements).flat();
  const stops = buildStops({ locations, requirements, dispatchStatus });

  // Special Requirements
  const specialRequirements = getSpecialRequirements(dispatchLegs, locations);

  // Deadline remark
  const deadlineRemark = findDeadlineRemark(dispatchLegs);
  const lfd = firstLeg.lfdDate ?? null;
  const lastFreeDay = !!lfd && new Date(lfd).toDateString() === new Date().toDateString();

  // Missing Requirements
  const missingRequirements = locations
    .map(({ missingRequirements }) => missingRequirements)
    .flat()
    .map((name) => requirementTextMap[name]);

  const hasMissingRequirements = missingRequirements.length > 0;

  // Empty/load
  const hasLoad = !isNil(locations.find(({ emptyLoaded }) => emptyLoaded.toLowerCase().includes('load')));
  const hasEmpty = !isNil(locations.find(({ emptyLoaded }) => (emptyLoaded.toLowerCase().includes('empty'))));

  return {
    // Dispatch data
    ...firstLeg,
    startDate: firstLeg.appointmentDate,
    appointmentDate,
    appointmentTime,
    assignmentChanged,
    deadlineRemark,
    hasMissingRequirements,
    lastFreeDay,
    lfd,
    missingRequirements,
    priority,
    specialRequirements,
    status: dispatchStatus,
    hasLoad,
    hasEmpty,
    // Legs data
    currentLeg,
    firstLeg,
    legs: dispatchLegs,
    stops,
    locations,
    requirements,
  };
};

export default buildDispatchData;
