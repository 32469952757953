import React from 'react';
import cn from 'classnames';
import Button from 'shared/buttons/Button';

const defaultStyle = {};

const Modal = ({
  children, onClose, title, modalStyle, style = defaultStyle, className, modalClassName,
}) => {
  const containerClassName = cn(
    // eslint-disable-next-line max-len
    'overlap-modal-container fixed top-0 right-0 left-0 bottom-0 flex-1 w-full flex flex-col justify-start items-center bg-gray-500/75 overflow-y-auto',
    { [className]: !!className },
  );

  const innerModalClassName = cn(
    'overlap-modal bg-white text-text-120 shadow-modal rounded-md flex flex-col items-stretch',
    { [modalClassName]: !!modalClassName },
  );

  return (
    <div className={containerClassName} style={{ zIndex: 1001, ...style }}>
      <div className={innerModalClassName} style={modalStyle ?? {}}>
        {(!!onClose || !!title) && (
          <div
            className='flex gap-4 justify-between items-baseline px-5 py-3 shadow-md action-modal-header'
          >
            {!!title && <div className='flex-1 text-lg font-semibold text-left'>{title}</div>}
            {!!onClose && (
              <Button
                id='close-modal-button'
                title='Cancel'
                mode='link'
                size='small'
                onClick={onClose}
                className='ml-auto'
              />
            )}
          </div>
        )}
        <div className='flex-1 flex flex-col justify-start items-stretch' style={{ overflowY: 'auto' }}>
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
