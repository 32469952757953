import { NOT_ACCEPTED_STATUS } from '../consts';
import isNil from 'lodash/isNil';

const getStopPropByLocations = (propName, orig, dest) => {
  const destValue = dest[propName];
  const origValue = orig[propName];

  if (!isNil(destValue) && destValue.length > 0) {
    return destValue;
  }

  if (!isNil(origValue) && origValue.length > 0) {
    return origValue;
  }

  return null;
};

const buildStops = ({ locations, requirements, dispatchStatus }) => {
  const buildRequirements = (location, isOrigCompleted, arrivedAt) => {
    const isDisabled = !isOrigCompleted || dispatchStatus === NOT_ACCEPTED_STATUS || !arrivedAt || arrivedAt === '';
    const locationId = location.uuid;

    const completed = requirements
      .filter(({ dispatchLocationId }) => dispatchLocationId === locationId)
      .map((r) => ({
        ...r, requirement: r.type, data: r, locationId, state: 'completed',
      }));

    const missing = location.missingRequirements
      .map((requirement) => ({ requirement, locationId, state: isDisabled ? 'disabled' : 'missing' }));

    const required = [...location.requiredOnArrival, ...location.requiredOnDeparture]
      .map((requirement) => ({ requirement, locationId, state: isDisabled ? 'disabled' : 'active' }));

    return [completed, missing, required].flat();
  };

  // Build Stops
  const stops = locations.reduce((acc, location, ind) => {
    let stop = null;

    const origDepartedAt = ind === 0 ? null : locations[ind - 1].departedAt;
    const isOrigCompleted = ind === 0 ? true : (origDepartedAt && origDepartedAt !== '');

    // First and Last stops
    if (ind === 0 || (locations.length > 1 && ind === (locations.length - 1))) {
      const requirements = buildRequirements(location, isOrigCompleted, location.arrivedAt);
      stop = { ...location, requirements };
    } else if (location.name === locations[ind + 1]?.name) {
      // Inter stops
      const dest = location.origdest === 'orig' ? locations[ind + 1] : location;
      const orig = location.origdest === 'orig' ? location : locations[ind + 1];

      const getStopProp = (propName) => getStopPropByLocations(propName, orig, dest);

      const arrivedAt = getStopProp('arrivedAt');

      const destRequirements = buildRequirements(dest, isOrigCompleted, arrivedAt);
      const origRequirements = buildRequirements(orig, isOrigCompleted, arrivedAt);
      const requirements = [destRequirements, origRequirements].flat();

      stop = {
        // Dispatch data
        driverId: dest.driverId,
        name: dest.name,
        actionType: getStopProp('actionType'),
        deadlineRemarks: getStopProp('deadlineRemarks'),
        doorNumbers: getStopProp('doorNumbers'),
        driverInstructions: getStopProp('driverInstructions'),
        indicators: getStopProp('indicators'),
        pickupNumber: getStopProp('pickupNumber'),
        type: dest.type,
        requirements,
        // Equipment
        waitingThreshold: dest.waitingThreshold,
        requireChassis: dest.requireChassis || orig.requireChassis,
        requireSealVerification: dest.requireSealVerification || orig.requireSealVerification,
        // Dates
        appointmentDate: getStopProp('appointmentDate'),
        appointmentTime: getStopProp('appointmentTime'),
        arrivedAt,
        departedAt: getStopProp('departedAt'),
        // Adress (destination)
        address1: dest.address1,
        address2: dest.address2,
        postalCode: dest.postalCode,
        region: dest.region,
        locality: dest.locality,
        // Orig and Dest
        orig,
        dest,
        origDispatchId: orig.dispatchId,
        destDispatchId: dest.dispatchId,
        uuidOrig: orig.uuid,
        uuidDest: dest.uuid,
        memoDest: dest.memo,
        memoOrig: orig.memo,
      };
    }

    if (!stop) {
      return acc;
    }

    // Arriver and Depart states
    const getArriveState = () => {
      if (!isNil(stop.arrivedAt) && stop.arrivedAt !== '') {
        return 'completed';
      }
      // If has missing inspection on the prev stop - Arrive is disabled
      if (dispatchStatus === NOT_ACCEPTED_STATUS
              || !isOrigCompleted
              || (acc.length > 0 && acc[acc.length - 1]?.missingRequirements?.includes('inspection'))) {
        return 'disabled';
      }
      if (stop.requirements.find(({ requirement }) => requirement === 'missingArrivedAt')) {
        return 'missing';
      }
      return 'active';
    };

    const arriveState = getArriveState();

    // Depart State
    const getDepartState = () => {
      if (stop.departedAt && stop.departedAt !== '') {
        return 'completed';
      }
      if (dispatchStatus === NOT_ACCEPTED_STATUS
        || !isOrigCompleted
        || stop.requirements.some(({ requirement, state }) => requirement === 'inspection' && state !== 'completed')
        || isNil(stop.arrivedAt)
        || stop.arrivedAt === '') {
        return 'disabled';
      }
      if (stop.requirements.find(({ requirement }) => requirement === 'missingDepartAt')) {
        return 'missing';
      }
      return 'active';
    };

    const departState = getDepartState();
    const result = { ...stop, arriveState, departState };

    return [...acc, result];
  }, []);

  return stops;
};

export default buildStops;
